@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

/* You can add global sass to this file, and also import other style files */

$base-color: #149A58;
$disable-background-color: #CCC;
$dropdown-arrow-color: #149A58;
.multiselect-dropdown {
  position: relative;
  min-width: 200px !important;
  font-size: 0.9rem !important;
  margin-top: 8px;

  .dropdown-btn {
    min-height: 42px !important;
    display: inline-block;
    border: 1px solid #CCC !important;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;

    .selected-item {
      border: 1px solid $base-color !important;
      margin-right: 4px;
      background: $base-color !important;
      padding: 0px 5px;
      color: #fff;
      border-radius: 2px;
      float: left;

      a {
        text-decoration: none;
      }
    }

    .selected-item:hover {
      box-shadow: 1px 1px #959595;
    }

    .dropdown-multiselect__caret {
      line-height: 16px;
      display: block;
      position: absolute;
      box-sizing: border-box;
      width: 40px;
      height: 38px;
      right: 1px;
      top: 0px;
      padding: 4px 8px;
      margin: 0;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      transition: transform 0.2s ease;
    }

    .dropdown-multiselect__caret:before {
      content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" fill="%23149A58" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>') !important;
      width: 25px;
      position: absolute !important;
      top: 4px !important;
      right: 4px !important;
      border-width: 0 !important;
      pointer-events: none;
    }
  }

  .disabled {
    & > span {
      background-color: $disable-background-color;
    }
  }
}

.dropdown-list {
  position: absolute;
  padding-top: 6px;
  width: 100%;
  z-index: 9999;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  margin-top: 10px;
  box-shadow: none !important;

  ul {
    padding: 0px;
    list-style: none;
    overflow: auto;
    margin: 0px;
  }

  li {
    padding: 6px 10px;
    cursor: pointer;
    text-align: left;
  }

  .filter-textbox {
    border-bottom: 1px solid #ccc;
    position: relative;
    padding: 10px;

    input {
      border: 0px;
      width: 100%;
      padding: 0px 0px 0px 26px;
    }

    input:focus {
      outline: none;
    }
  }
}

.multiselect-item-checkbox:hover {
  background-color: #e4e3e3;
}

.multiselect-item-checkbox input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.multiselect-item-checkbox input[type='checkbox']:focus + div:before,
.multiselect-item-checkbox input[type='checkbox']:hover + div:before {
  border-color: $base-color !important;
  background-color: #f2f2f2;
}

.multiselect-item-checkbox input[type='checkbox']:active + div:before {
  transition-duration: 0s;
}

.multiselect-item-checkbox input[type='checkbox'] + div {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0px;
  color: #000;
}

.multiselect-item-checkbox input[type='checkbox'] + div:before {
  box-sizing: content-box;
  content: '';
  color: $base-color !important;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid $base-color !important;
  text-align: center;
  transition: all 0.4s ease;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
  box-sizing: content-box;
  content: '';
  background-color: $base-color !important;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:disabled + div:before {
  border-color: #cccccc;
}

.multiselect-item-checkbox
input[type='checkbox']:disabled:focus
+ div:before
.multiselect-item-checkbox
input[type='checkbox']:disabled:hover
+ div:before {
  background-color: inherit;
}

.multiselect-item-checkbox
input[type='checkbox']:disabled:checked
+ div:before {
  background-color: #cccccc;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:before {
  animation: borderscale 200ms ease-in;
  background: $base-color !important;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  transform: rotate(-45deg) scale(1);
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px $base-color !important;
  }
}

.mat-option {
  background: white;
}

.ql-tooltip {
  left: unset !important;
}

.ribbon-angled-primary {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 175px;
  height: 175px;
  text-align: center;
}

.ribbon-angled-primary span {
  text-align: center;
  line-height: 16px;
  transform: rotate(45deg);
  width: 170px;
  display: block;
  background: #149A58;
  padding: 0.25rem;
  position: absolute;
  top: 40px;
  right: -32px;
}

.ribbon-angled-primary span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #149A58;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #149A58;
}

.ribbon-angled-primary span::after {
  content: "";
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #149A58;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #149A58;
}


.dropdown-buttons {
  @apply relative inline-block;
  position: relative;

  & .dropdown-options {
    position: absolute;
    display: none;
    right: 0;
    width: 100%;
    top: 100%;
    z-index: 1;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px #fff;

    & > button, a {
      @apply w-full;
      text-align: left;
      border: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      cursor: pointer;
    }
  }

  &:hover .dropdown-options {
    @apply flex flex-col justify-stretch items-stretch;
  }
}

[type="checkbox"]:disabled {
  border-color: #ccc;
  background-color: #eee;
}
